import { createContext, useContext, useRef, useState } from "react";
import { usePunditUserContext } from "@circle-react/contexts";
import { useCheckConversations } from "../queries";
import { getLocalStorage, setLocalStorage } from "../utils";

export const SupportWidgetContext = createContext<any>({});
SupportWidgetContext.displayName = "SupportWidgetContext";

export const useSupportWidgetContext = () => {
  const context = useContext(SupportWidgetContext);
  if (!context) {
    throw new Error(
      "useSupportWidgetContext must be used within a SupportWidgetContextProvider",
    );
  }
  return context;
};

export const SupportWidgetContextProvider = ({ children }: any) => {
  const { isViewOnlyMasquerading, currentCommunity, currentCommunityMember } =
    usePunditUserContext();
  const [currentSpaceProperties, setCurrentSpaceProperties] = useState({
    name: "",
    emoji: "",
    custom_emoji_url: "",
    custom_emoji_dark_url: "",
  });
  const { data: { unread_conversations } = {} } = useCheckConversations(
    currentCommunityMember,
  );

  const shouldDisplayOnboarding =
    currentCommunityMember?.preferences?.has_seen_widget_onboarding === false; // Strict equality is on purpose here, we want to display onboarding only when `has_seen_widget_onboarding` is equal to false

  const isLiveChatEnabled =
    currentCommunity?.current_plan_tier == "basic" ||
    currentCommunity?.current_plan_tier == "professional" ||
    currentCommunity?.current_plan_tier == "business";

  const [isExpanded, setIsExpanded] = useState(
    () => getLocalStorage()?.isExpanded || false,
  );
  const expandWidget = () => {
    setLocalStorage({ ...getLocalStorage(), isExpanded: true });
    setIsExpanded(true);
  };
  const collapseWidget = () => {
    setLocalStorage({ ...getLocalStorage(), isExpanded: false });
    setIsExpanded(false);
  };
  const onWidgetClose = useRef<(() => void) | null>(null);
  const setOnWidgetClose = (callback: any) =>
    (onWidgetClose.current = callback);

  const [isOpen, setIsOpen] = useState(() =>
    isViewOnlyMasquerading ? false : getLocalStorage()?.isOpen || false,
  );
  const toggleWidget = ({ shouldTriggerOnWidgetClose = false } = {}) => {
    if (isViewOnlyMasquerading) {
      return;
    }

    if (
      shouldTriggerOnWidgetClose &&
      isOpen &&
      onWidgetClose.current !== null
    ) {
      onWidgetClose.current();
    } else {
      setLocalStorage({ ...getLocalStorage(), isOpen: !isOpen });
      setIsOpen(!isOpen);
    }
  };

  const openWidget = () => {
    setLocalStorage({ ...getLocalStorage(), isOpen: true });
    setIsOpen(true);
  };

  const formValues = getLocalStorage()?.formValues;
  const { text, type, area, priority } = formValues || {};
  const [isDraftConversationPresent, setIsDraftConversationPresent] = useState(
    text || type || area || priority,
  );

  const [unreadConversations, setUnreadConversations] = useState(
    unread_conversations || [],
  );

  return (
    <SupportWidgetContext.Provider
      value={{
        isExpanded,
        expandWidget,
        collapseWidget,
        isOpen,
        toggleWidget,
        isDraftConversationPresent,
        setIsDraftConversationPresent,
        currentSpaceProperties,
        setCurrentSpaceProperties,
        unreadConversations,
        setUnreadConversations,
        liveChatEnabled: isLiveChatEnabled,
        shouldDisplayOnboarding,
        setOnWidgetClose,
        openWidget,
      }}
    >
      {children}
    </SupportWidgetContext.Provider>
  );
};
